import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function WhoHulol() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    
    "/assets/worker2.png",
    "/assets/worker3.png",
    "/assets/worker4.png",
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div
      id="whuhulol"
      className="flex flex-col md:flex-row relative w-full md:mt-[15rem] overflow-hidden"
    >
      <img
        src="/assets/asset.svg"
        className="w-full h-auto md:min-w-[100%] absolute -left-1/2 mt-10 bottom-0 md:h-[70%]"
        alt=""
      />
      <div className="w-full md:hidden md:w-[40%] relative h-[300px] md:h-[500px]">
        {/* Left and Right Navigation */}
        <button
          className="absolute left-4 md:left-0 top-1/2 transform -translate-y-1/2 bg-[#d7eabd] rounded-full p-2 md:p-3 z-10"
          onClick={handlePrev}
        >
          <img
            src="/assets/arrow.png"
            className="w-8 md:w-[50px] rotate-180"
            alt=""
          />
        </button>

        <div className="relative w-full h-full overflow-hidden">
          <AnimatePresence initial={false} mode="wait">
            <motion.img
              key={currentIndex}
              src={images[currentIndex]}
              alt={`worker-${currentIndex}`}
              className="absolute top-0 left-0 w-full h-full object-cover"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1.2 }}
              transition={{ duration: 0.5 }}
            />
          </AnimatePresence>
        </div>
        <button
          className="absolute right-4 md:right-0 top-1/2 transform -translate-y-1/2 bg-[#d7eabd] rounded-full p-2 md:p-3 z-10"
          onClick={handleNext}
        >
          <img src="/assets/arrow.png" className="w-8 md:w-[50px]" alt="" />
        </button>
      </div>
      <div className="w-full md:w-1/2 px-4 md:px-16 flex flex-col justify-center items-start mt-8 md:mt-0">
        <p className="text-[#8dc341] md:ml-auto text-3xl md:text-[120px] mb-4 md:mb-16 font-somar-sans-bold text-start">
          من حلول
        </p>
        <p className="text-[#2D2D72] text-base md:text-2xl text-start">
          ﻧﺨﺘﺺ ﺑﺘﻘﺪﻳﻢ ﺧﺪﻣﺎت اﻟﺘﻤﻮﻳﻦ اﻟﻐﺬاﺋﻲ ﻋﺎﻟﻴﺔ اﻟﺠﻮدة ﺣﻴﺚ ﻧﻮﻓﺮ ﻟﻚ اﻟﺼﻠﺼﺎت
          اﻟﻤﻐﻠﻔﺔ ﺣﺮارﻳﺎ ً ﺑﺠﻤﻴﻊ أﻧﻮاﻋﻬﺎ اﻟﺠﺎﻫﺰة او اﻟﻤﺤﻀﺮﺓ ﺣﺴﺐ اﻟﻄﻠﺐ ﺑﻤﻜﻮﻧﺎت
          ﺧﺎﺹﺔ ﺑﻚ ﻓﻘﻂ, ﺑﺎﻹﺿﺎﻓﺔ اﻟﻰ اﻟﺪﺟﺎج واﻟﻠﺤﻮم واﻟﺠﺎﻫﺰة ﻟﻠﺘﺤﻀﻴﺮ ﻣﻨﻪ ﻟﺘﻼﺋﻢ
          اﻻﺣﺘﻴﺎﺟﺎت وﺗﻘﻠﻞ ﺗﻜﺎﻟﻴﻒ اﻟﻤﺘﻄﻠﺒﺎت اﻟﻐﺬاﺋﻴﺔ ﻟﻠﻤﻨﺸﺌﺎت واﻟﻘﻄﺎﻋﺎت اﻟﻤﺨﺘﻠﻔﺔ.
        </p>
      </div>
      <div className="w-full md:block hidden md:w-[40%] relative h-[300px] md:h-[500px]">
        {/* Left and Right Navigation */}
        <button
          className="absolute left-4 md:left-0 top-1/2 transform -translate-y-1/2 bg-[#d7eabd] rounded-full p-2 md:p-3 z-10"
          onClick={handlePrev}
        >
          <img
            src="/assets/arrow.png"
            className="w-8 md:w-[50px] rotate-180"
            alt=""
          />
        </button>

        <div className="relative w-full h-full overflow-hidden">
          <AnimatePresence initial={false} mode="wait">
            <motion.img
              key={currentIndex}
              src={images[currentIndex]}
              alt={`worker-${currentIndex}`}
              className="absolute top-0 left-0 w-full h-full object-cover"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1.2 }}
              transition={{ duration: 0.5 }}
            />
          </AnimatePresence>
        </div>
        <button
          className="absolute right-4 md:right-0 top-1/2 transform -translate-y-1/2 bg-[#d7eabd] rounded-full p-2 md:p-3 z-10"
          onClick={handleNext}
        >
          <img src="/assets/arrow.png" className="w-8 md:w-[50px]" alt="" />
        </button>
      </div>
    </div>
  );
}
