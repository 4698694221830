import Footer from "./footer";
import Navbar from "./navbar";

export default function ContactUs() {
  return (
    <>
      <div className="flex flex-col mt-10 mb-10 md:mt-[10rem] md:mb-[10rem] relative items-center">
        <img
          src="/assets/asset.svg"
          className="w-full h-auto -z-10 left-0 mt-10 bottom-0 md:h-[70%] absolute"
          alt=""
        />
        <p className="text-[#8dc341] text-4xl md:text-[120px] mb-10 md:mb-[10rem] font-somar-sans-bold text-center md:text-start">
          تواصل معنا
        </p>
        <div
          className="flex flex-col md:flex-row w-full justify-center items-center md:gap-10"
          dir="rtl"
        >
          <div className="w-full md:mr-[10rem] md:w-1/2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d228792.25326048568!2d49.8277273910512!3d26.36277673809054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e361d32276b3403%3A0xefd901ec7a5e5676!2sDammam%20Saudi%20Arabia!5e0!3m2!1sen!2slb!4v1726430077296!5m2!1sen!2slb"
              className="w-full h-[300px] md:h-[400px] border-0"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="flex flex-col gap-6 md:gap-10 w-full md:w-1/2 justify-center mt-8 md:mt-0">
            <div className="flex flex-row items-center gap-4 text-[#2D2D72] font-bold">
              <img
                src="/assets/pin.png"
                alt="location"
                className="w-8 md:w-[50px]"
              />
              <p>KSA - Dammam P.O. 32442</p>
            </div>
            <div className="flex flex-row items-center gap-4 text-[#2D2D72] font-bold">
              <img
                src="/assets/telephone.png"
                alt="phone"
                className="w-8 md:w-[50px]"
              />
              <p>+966 55 410 0950</p>
            </div>
            <div className="flex flex-row items-center gap-4 text-[#2D2D72] font-bold">
              <img
                src="/assets/email.png"
                alt="mail"
                className="w-8 md:w-[50px]"
              />
              <p>info@hulol.sa</p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-[50%] mt-10 md:mt-[10rem] px-4 md:px-0">
          <form action="" className="flex flex-col gap-4 md:gap-8">
            <div className="flex flex-col md:flex-row gap-4 md:gap-10">
              <div className="w-full md:w-1/2">
                <input
                  type="text"
                  className="border-[#2D2D72] w-full py-2 border-2"
                  placeholder="الايميل"
                  dir="rtl"
                />
              </div>
              <div className="w-full md:w-1/2">
                <input
                  type="text"
                  className="border-[#2D2D72] w-full py-2 border-2"
                  dir="rtl"
                  placeholder="الاسم"
                />
              </div>
            </div>
            <div className="w-full">
              <textarea
                className="border-[#2D2D72] border-2 w-full py-2"
                rows={5}
                placeholder="النص"
                dir="rtl"
              ></textarea>
            </div>
            <div className="flex justify-end mt-4 md:mt-16">
              <button className="rounded-full px-6 py-2 md:px-10 md:py-3 bg-[#2D2D72] text-white">
                أرسل
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
