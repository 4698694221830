import Hero from "../components/hero";
import Product from "../components/products";
import WhoHulol from "../components/whohulol";
import WhyHulol from "../components/whyhuol";


export default function HomePage(){
    return (
        <>
        <Hero />
        <WhoHulol />
        <WhyHulol />
        <Product />
        </>
    )
}