export default function Footer() {
  return (
    <div className="flex flex-col w-full">
      {/* Main Section */}
      <div
        dir="rtl"
        className="bg-[#2D2D72] flex flex-col md:flex-row md:gap-10 md:py-16 md:px-12 p-4"
      >
        {/* Logo Section */}
        <div className="flex justify-center md:justify-start w-full md:w-1/2 mb-4 md:mb-0">
          <img
            src="assets/logo-blue.svg"
            className="w-2/3 md:w-3/4"
            alt="Company Logo"
          />
        </div>
        {/* Text Section */}
        <div className="text-white text-center md:text-start flex items-center justify-center md:justify-start md:leading-[40px] md:text-2xl w-full md:w-1/2">
          ﺗﻘﺪم ﺷﺮﻛﺔ ﺣﻠﻮل ﻟﺤﻈﺔ ﺧﺪﻣﺎت اﻟﺘﻤﻮﻳﻦ اﻟﻐﺬاﺋﻲ وﺧﺪﻣﺎت اﻟﻤﻄﺎﻋﻢ ﺣﻴﺚ ﻧﻮﻓﺮ ﻟﻚ
          اﻟﺼﻮﺻﺎت ﺑﺠﻤﻴﻊ اﻧﻮاﻋﻬﺎ , او ﺗﺤﻀﻴﺮﻫﺎ ﺑﻤﻜﻮﻧﺎت ﺧﺎﺻﺔ ﺑﻚ ﻓﻘﻂ. ﺑﺎﻹﺿﺎﻓﺔ اﻟﻰ
          اﻟﺪﺟﺎج واﻟﻠﺤﻮم ﺑﺨﻴﺎرات ﻣﺘﻨﻮﻋﺔ , ﻧﺤﻦ ﻣﺘﺎﺣﻮن ﻟﺨﺪﻣﺔ أي ﻋﻤﻴﻞ ﺣﺴﺐ رﻏﺒﺘﻪ
          وﺣﺴﺐ اﻟﻨﺸﺎط اﻟﺬي ﻳﺨﺪﻡ ﻋﻤﻠﻪ ﻟﺘﺴﻬﻴﻞ .ﻣﺘﻄﻠﺒﺎت ﻣﻨﺸﺄﺗﻪ واﺣﺘﻴﺎﺟﺎﺗﻪ ﺑﻜﻞ ﺟﻮدة
          وﺗﻮﻓﻴﺮ
        </div>
      </div>

      {/* Social Media and Contact Links */}
      <div className="bg-[#2D2D72] py-4">
        <ul className="flex flex-wrap items-center justify-center gap-4 md:gap-8">
          <li>
            <a href="#" aria-label="Email">
              <img src="/assets/mail.png" className="w-8 md:w-10" alt="Email" />
            </a>
          </li>
          <li>
            <a href="#" aria-label="Phone">
              <img
                src="/assets/phone.png"
                className="w-8 md:w-10"
                alt="Phone"
              />
            </a>
          </li>
          <li>
            <a href="#" aria-label="Instagram">
              <img
                src="/assets/insta.png"
                className="w-8 md:w-10"
                alt="Instagram"
              />
            </a>
          </li>
          <li>
            <a href="#" aria-label="WhatsApp">
              <img
                src="/assets/whatsapp.png"
                className="w-8 md:w-10"
                alt="WhatsApp"
              />
            </a>
          </li>
          <li>
            <a href="#" aria-label="Other">
              <img src="/assets/x.png" className="w-8 md:w-10" alt="Other" />
            </a>
          </li>
        </ul>
      </div>

      {/* Footer Bottom */}
      <div className="text-[#2D2D72] text-center py-4 md:py-8 px-4 md:px-10">
        <p className="text-sm md:text-lg">
          CopyRight Hulol SA 2024&copy; ﺟﻤﻴﻊ اﻟﺤﻘﻮق ﻣﺤﻔﻮﻇﺔ
        </p>
      </div>
    </div>
  );
}
