import { useState } from "react";
import { motion, useAnimation } from "framer-motion";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const controls = useAnimation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    controls.start({
      opacity: isOpen ? 0 : 1,
      x: isOpen ? -100 : 0,
      transition: { duration: 0.3 },
    });
  };

  return (
    <div
      className="font-somar-sans-black  md:py-5 shadow-md shadow-gray-400 bg-[#e7f1d9] w-full flex flex-row md:flex-row justify-between items-center px-4 md:px-10 py-4   text-[#2d2d72]"
      dir="rtl"
    >
      <ul className="md:flex flex-row gap-10 hidden ">
        <li className="py-2 md:py-0">
          <a href="/">الرئيسية</a>
        </li>
        <li className="py-2 md:py-0">
          <a href="/products">خدماتنا</a>
        </li>
        <li className="py-2 md:py-0">
          <a href="/#whuhulol">من نحن </a>
        </li>
        <li className="py-2 md:py-0">
          <a href="/contact">تواصل معنا</a>
        </li>
      </ul>
      <div className="flex items-center w-full md:w-auto">
        {/* Toggle Button */}
        <button
          className="md:hidden text-lg md:text-2xl px-4 py-2 bg-[#2d2d72] text-white rounded"
          onClick={toggleMenu}
        >
          {isOpen ? "✕" : "☰"}
        </button>
      </div>
      <div
        className={`w-full md:w-auto ${isOpen ? "block" : "hidden"} md:block`}
      >
        <motion.ul
          className="flex flex-col md:flex-row w-full md:w-fit font-somar-bold text-lg md:text-3xl items-center md:gap-8 text-center"
          initial={{ opacity: 0, x: -100 }}
          animate={controls}
          exit={{ opacity: 0, x: -100 }}
        >
          <li className="py-2 md:py-0">
            <a href="/">الرئيسية</a>
          </li>
          <li className="py-2 md:py-0">
            <a href="/products">خدماتنا</a>
          </li>
          <li className="py-2 md:py-0">
            <a href="/#whuhulol">من نحن </a>
          </li>
          <li className="py-2 md:py-0">
            <a href="/contact">تواصل معنا</a>
          </li>
        </motion.ul>
      </div>
      <div className="w-[100px] flex justify-center items-center mt-4 md:mt-0">
        <a href="" className="w-[100px] flex justify-center items-center">
          <img
            src="assets/logo-blue1.svg"
            className="object-contain"
            alt="Logo"
          />
        </a>
      </div>
    </div>
  );
}
