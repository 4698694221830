import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import HomePage from "./pages/homepage";
import Navbar from './components/navbar'
import Footer from "./components/footer";
import ContactUs from './components/contactus';
import Services from "./components/services";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <div className="bg-white">
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/products" element={<Services />} />
      </Routes>
      <Footer />
    </div>
  </Router>
);

reportWebVitals();
